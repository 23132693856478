<template>
  <div v-if="errors.length > 0" class="row alert alert-danger text-left">
    <div class="col-md-12">
      <h4>Errors</h4>
      <ul>
        <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DangerAlert',
  props: {
    errors: Array,
  },
};
</script>

<style lang="scss" scoped>
  /*@import "../../styles/components/danger-alert";*/
</style>
